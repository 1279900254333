import { Badge } from '../../components/badge/badge'
import { PriceDropBadge } from './price-drop-badge/price-drop-badge'
import { getDealsText } from '../listing/get-deals-text'
import styles from './discount-badge.module.css'
import type { ListingCardPreview_ListingFragment } from './__generated__/listing-card-preview.gql'
import clsx from 'clsx'

type ListingCardPreviewBadgeProps = {
  listing: Partial<ListingCardPreview_ListingFragment>
  isListingCardPreviewRedesign?: boolean
  isThankYouModal?: boolean
}

export function DiscountBadge({
  listing,
  isListingCardPreviewRedesign,
  isThankYouModal,
}: ListingCardPreviewBadgeProps) {
  const dealsBadgeText = getDealsText(listing)

  return (
    <>
      {dealsBadgeText ? (
        <Badge
          data-tag_action="ignore"
          data-tid="deals_badge"
          text={dealsBadgeText}
          className={clsx(
            styles.badge,
            isThankYouModal && styles.thankYouModalBadge
          )}
        />
      ) : (
        !listing.isUnpaid &&
        listing.hasPriceDrops && (
          <PriceDropBadge
            className={styles.priceDropBadge}
            listing={listing}
            isListingCardPreviewRedesign={isListingCardPreviewRedesign}
            isThankYouModal={isThankYouModal}
          />
        )
      )}
    </>
  )
}
